import React from "react";
import { Link } from "react-router-dom";
import Header from "../components/Header";
import Footer from "../components/Footer";
import AsMansonImg from "../images/asmanson-home.jpg";
import GadgetsImg from "../images/gadgets-galore.jpg";
import PasswordImg from "../images/password-generator.jpg";
import SnakeImg from "../images/Snake-game.jpg";
import MunchkinsImg from "../images/munchkins-new.jpg";
import PaymentImg from "../images/card-details.jpg";
import SweetImg from "../images/sticky&sweet.jpg";
import RpsImg from "../images/RPS.jpg";
import PacmanImg from "../images/pacman.jpg";

const Work = () => {
  window.addEventListener("scroll", checkBoxes);

  checkBoxes();

  function checkBoxes() {
    const boxes = document.querySelectorAll(".box");
    const triggerBottom = (window.innerHeight / 9) * 6;

    boxes.forEach((box) => {
      const boxTop = box.getBoundingClientRect().top;
      if (boxTop < triggerBottom) {
        box.classList.add("show");
      } else {
        box.classList.remove("show");
      }
    });
  }

  return (
    <>
      <Header />
      <div className="main-container">
        <section className="my-work" id="work">
          <h2 className="title">My Work</h2>
          <p>
            My portfolio includes a collection of projects that demonstrate my
            proficiency in different programming languages and design
            approaches. Furthermore, the platform itself was developed using
            React.js.
          </p>
          <br />
          <p>
            Currently, I am working on a MERN project for my present employer,
            where I am looking to replace the existing website. The new website
            will have various user roles, including Admin Accounts, which will
            have the ability to manage bookkeeping, create user accounts, and
            set customer discounts. Additionally, there will be Staff Accounts
            that will have access to customer profiles, an in-store till system
            with preset discounts for credit account holders, and modals for
            credit status alerts.
          </p>
          <br />
          <p>
            Furthermore, the new site will also include a Repair Booking System,
            which will allow the intake of machines for repair. This system will
            capture details such as pictures, fault descriptions, customer
            contacts, and post-diagnosis repair cost breakdowns. Another feature
            of the website will be Trade Credit Accounts, which are
            admin-created accounts with specialized e-commerce access. These
            accounts will provide access to features such as there discounted
            products, stock levels, purchase histories, and delivery/product
            information. Lastly, there will be Trade Cash Accounts, where
            customers can create accounts directly on the website. These
            accounts will grant them e-commerce access with features like stock
            monitoring, purchase history, and product details.
          </p>
          <div className="portfolio">
            <div className="portfolio-item box">
              <div className="portfolio-description-left">
                <h2>A.S.Manson</h2>
                <p>
                  I created and developed a dynamic informational website for my
                  present employer. The website utilizes javascript to establish
                  a carousel, a completely adaptable navigation menu, and an
                  extra information segment that redirects you to the specific
                  item you were viewing earlier.
                </p>
              </div>
              <div className="portfolio-image left">
                <Link to="http://www.asmanson.co.uk/" target="_blank">
                  <img
                    src={AsMansonImg}
                    width="480"
                    height="auto"
                    alt="a website for a welding suppliers"
                  />
                </Link>
              </div>
            </div>
            <div className="portfolio-item box">
              <div className="portfolio-description-right">
                <h2>Gadgets Galore</h2>
                <p>
                  This simulated online store was created utilizing the MERN
                  stack, which includes React.js, MongoDB, Node.js and Redux
                  Toolkit. It also incorporates Bootstrap for styling, JSON web
                  tokens for authentication and password encryption for
                  security. To access the admin account, please use the
                  following credentials: Username: admin / Password: 123456.
                  (The website is hosted on render.com, which may result in a
                  slightly longer loading time. So I kindly ask for your
                  patience.)
                </p>
              </div>
              <div className="portfolio-image right">
                <Link to="https://gagets-galore.onrender.com/" target="_blank">
                  <img
                    src={GadgetsImg}
                    width="400"
                    height="auto"
                    alt="An E-commerce store selling tech"
                  />
                </Link>
              </div>
            </div>

            <div className="portfolio-item box">
              <div className="portfolio-description-left">
                <div>
                  <h2>Password Generator</h2>
                  <p>
                    A generator developed using vanilla javascript allows you to
                    select the desired password length and choose from various
                    character groups to include. Subsequently, two random
                    passwords will be generated for you to choose from. You can
                    then click on the generated password to easily copy it to
                    your clipboard.
                  </p>
                </div>
              </div>
              <div className="portfolio-image left">
                <Link to="../../password-generator/index.html" target="_blank">
                  <img
                    src={PasswordImg}
                    width="480"
                    height="auto"
                    alt="password-generator where you can choose the password length and what characters you'd like to include"
                  />
                </Link>
              </div>
            </div>

            <div className="portfolio-item box">
              <div className="portfolio-description-right">
                <div>
                  <h2>Snake</h2>
                  <p>
                    This was a fun exercise where I created a classic Snake game
                    using HTML, vanilla JavaScript and CSS.
                  </p>
                </div>
              </div>
              <div className="portfolio-image right">
                <Link to="../../Snake/index.html" target="_blank">
                  <img
                    src={SnakeImg}
                    width="480"
                    height="auto"
                    alt="classic snake game using html, css and vanilla javascript"
                  />
                </Link>
              </div>
            </div>

            <div className="portfolio-item box">
              <div className="portfolio-description-left">
                <h2>Munchkins Cupcakes</h2>
                <p>
                  I designed a bakery-themed mock site that is fully responsive.
                  By combining different colors and typography, I aimed to
                  deliver a distinctive user experience.
                </p>
              </div>
              <div className="portfolio-image left">
                <Link to="../../Munchkins-site/index.html" target="_blank">
                  <img
                    width="480"
                    height="auto"
                    src={MunchkinsImg}
                    alt="a full responsive mock website for a bakery"
                  />
                </Link>
              </div>
            </div>

            <div className="portfolio-item box">
              <div className="portfolio-description-right">
                <div>
                  <h2>Card Details</h2>
                  <p>
                    The card payments page presented a challenge in maintaining
                    the proper alignment of all elements while ensuring
                    responsiveness. I utilized vanilla Javascript to guarantee
                    that all sections were populated with accurate and valid
                    data.
                  </p>
                </div>
              </div>
              <div className="portfolio-image right">
                <Link to="../../Card-payment/index.html" target="_blank">
                  <img
                    src={PaymentImg}
                    width="480"
                    height="auto"
                    alt="a mock card details page using vanilla javascript for card validation"
                  />
                </Link>
              </div>
            </div>

            <div className="portfolio-item box">
              <div className="portfolio-description-left">
                <h2>Sweet & Sticky</h2>
                <p>
                  I developed a Beekeepers website with an innovative method for
                  presenting the navigation menu. Through the use of CSS, I
                  implemented a technique that rotates the page to reveal the
                  hive and navigation links underneath.
                </p>
              </div>
              <div className="portfolio-image left">
                <Link to="../../Bee-keeper/index.html" target="_blank">
                  <img
                    src={SweetImg}
                    width="480"
                    height="auto"
                    alt="a mock website for beekeepers"
                  />
                </Link>
              </div>
            </div>

            <div className="portfolio-item box">
              <div className="portfolio-description-right">
                <div>
                  <h2>Rock Paper Scissors</h2>
                  <p>
                    I utilized vanilla JavaScript to develop the gameplay for a
                    digital rock, paper, scissors game pitting you against the
                    computer. The question remains: who will emerge victorious?
                    Will you prevail, or will you succumb to the computer's
                    taunts?
                  </p>
                </div>
              </div>
              <div className="portfolio-image right">
                <Link
                  to="https://codepen.io/3weavers/pen/YzvorEy"
                  target="_blank"
                >
                  <img
                    src={RpsImg}
                    width="480"
                    height="auto"
                    alt="rock paper scissors game using vanilla javascript"
                  />
                </Link>
              </div>
            </div>

            <div className="portfolio-item box">
              <div className="portfolio-description-left">
                <div>
                  <h2>Pacman</h2>
                  <p>
                    I thoroughly enjoyed the exciting challenge of creating a
                    traditional Pacman game using vanilla Javascript, HTML, and
                    CSS. This project truly put my skills to the test and
                    allowed me to showcase my expertise. If you would like to
                    view my CodePen version of the game, kindly click <br />
                    <Link
                      className="page-link"
                      to="https://codepen.io/3weavers/pen/PoRKPJX"
                      target="_blank"
                    >
                      here
                    </Link>
                  </p>
                </div>
              </div>
              <div className="portfolio-image left">
                <a href="../../Pacman/index.html" target="_blank">
                  <img
                    src={PacmanImg}
                    width="480"
                    height="auto"
                    alt="classic pacman game made with vanilla javascript"
                  />
                </a>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </>
  );
};

export default Work;
