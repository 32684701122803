import React from "react";
import { Link, Outlet } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLinkedin,
  faCodepen,
  faGithub,
} from "@fortawesome/free-brands-svg-icons";
import { faEnvelope, faHouse } from "@fortawesome/free-solid-svg-icons";
import {
  faAddressCard,
  faPen,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import { faGlobePointer } from "@fortawesome/pro-solid-svg-icons";

const Footer = () => {
  return (
    <>
      <footer>
        <div className="link-container">
          <ul>
            <li>
              <Link to="mailto:getintouch@andrew-weaver.net">
                <FontAwesomeIcon className="social__icon" icon={faEnvelope} />
              </Link>
            </li>
            <li>
              <Link
                to="https://www.linkedin.com/in/andrew-c-weaver/"
                target="_blank"
              >
                <FontAwesomeIcon className="social__icon" icon={faLinkedin} />
              </Link>
            </li>
            <li>
              <Link to="https://github.com/3weavers" target="_blank">
                <FontAwesomeIcon className="social__icon" icon={faGithub} />
              </Link>
            </li>
            <li>
              <Link to="https://codepen.io/3weavers" target="blank">
                <FontAwesomeIcon className="social__icon" icon={faCodepen} />
              </Link>
            </li>
          </ul>
        </div>

        <ul className="footer-nav">
          <li>
            <Link to="/" className="footer__nav__link">
              <FontAwesomeIcon className="footer-icon" icon={faHouse} />
              Home
            </Link>
          </li>
          <li>
            <Link to="/about" className="footer__nav__link">
              <FontAwesomeIcon className="footer-icon" icon={faAddressCard} />
              About Me
            </Link>
          </li>
          <li>
            <Link to="/work" className="footer__nav__link">
              <FontAwesomeIcon className="footer-icon" icon={faGlobePointer} />
              My Work
            </Link>
          </li>
          <li>
            <Link to="/blog" className="footer__nav__link">
              <FontAwesomeIcon className="footer-icon" icon={faPen} />
              Blog
            </Link>
          </li>
          <li>
            <Link to="/contact" className="footer__nav__link">
              <FontAwesomeIcon className="footer-icon" icon={faPhone} />
              Contact
            </Link>
          </li>
        </ul>
        <small>
          <Link to="/" className="developer__link">
            Created by Andrew Weaver © <span>{new Date().getFullYear()}</span>
          </Link>
        </small>
      </footer>
      <Outlet />
    </>
  );
};

export default Footer;
