import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Link } from "react-router-dom";
import ReduxImg from "../images/reduxImg.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCss3,
  faHtml5,
  faJs,
  faNodeJs,
  faReact,
} from "@fortawesome/free-brands-svg-icons";
import {
  faDatabase,
  faListCheck,
  faMobileScreen,
} from "@fortawesome/free-solid-svg-icons";
import Background from "../images/background.jpg";

const Home = () => {
  return (
    <>
      <Header />
      <section
        className="intro slanted"
        style={{
          backgroundImage: `url(${Background})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <div className="intro-container">
          <h2>
            Hi,<span> I'm Andrew!</span>
          </h2>
          <h1> I'm a passionate Web Developer & Designer</h1>
          <p>
            Ever since I can remember, I've been fuelled by an insatiable
            curiosity, a relentless drive to learn, and an undying passion for
            innovation. Coding isn't just a profession for me—it's an
            ever-evolving puzzle that captivates my mind, pushing me to
            transcend boundaries and elevate standards.
          </p>
          <div className="btn-container">
            <Link to="/contact" className="btn intro-btn">
              Contact Me
            </Link>
            <Link to="/work" className="btn intro-btn">
              My Work
            </Link>
          </div>
        </div>
      </section>
      <div className="main-container">
        <section>
          <div className="why-me">
            <h2>
              So Why <br /> Choose Me?
            </h2>
            <p className="why-text">
              I am proficient in the pillars of web development — JavaScript,
              TypeScript, React.js, Express.js, Node.js, MongoDB, Redux, and UI
              design. I bring a robust skill set to the table. But here's the
              thing: I'm not bound by these technologies alone. If your vision
              demands a different toolkit, consider me your adaptable ally. I
              thrive on learning, adapting, and mastering new technologies,
              ensuring your project benefits from the best-suited solutions.
            </p>
          </div>
          <div className="points-text">
            <div>
              <h3>Passionate Learner</h3>
              <p>
                My commitment to continuous learning ensures I stay ahead of
                industry trends, offering you cutting-edge solutions tailored to
                your needs.
              </p>
            </div>
            <div>
              <h3>Problem-Solver</h3>
              <p>
                Challenges aren't setbacks; they're opportunities. With a
                solution-oriented mindset, I transform complexities into
                streamlined, efficient and effective outcomes.
              </p>
            </div>
            <div>
              <h3>Versatile Expertise</h3>
              <p>
                While I excel in foundational technologies, my agility and
                adaptability mean I'm always ready to embrace new tools and
                techniques, optimising your project's success.
              </p>
            </div>
          </div>
        </section>
        <section className="skills">
          <h2 className="skills-title">Current Skill Set</h2>
          <div className="my-skills">
            <div>
              <h2>
                HTML <FontAwesomeIcon className="i" icon={faHtml5} />
              </h2>
            </div>
            <div>
              <h2>
                CSS <FontAwesomeIcon className="i" icon={faCss3} />
              </h2>
            </div>
            <div>
              <h2>
                Javascript <FontAwesomeIcon className="i" icon={faJs} />
              </h2>
            </div>
            <div>
              <h2>
                TypeScript <FontAwesomeIcon className="i" icon={faJs} />
              </h2>
            </div>
            <div>
              <h2>
                React.js <FontAwesomeIcon className="i" icon={faReact} />
              </h2>
            </div>
            <div>
              <h2>
                Responsive Design
                <FontAwesomeIcon className="i" icon={faMobileScreen} />
              </h2>
            </div>
            <div>
              <h2>
                Node.js <FontAwesomeIcon className="i" icon={faNodeJs} />
              </h2>
            </div>
            <div>
              <h2>
                Express.js <FontAwesomeIcon className="i" icon={faNodeJs} />
              </h2>
            </div>
            <div>
              <h2>
                MongoDB <FontAwesomeIcon className="i" icon={faDatabase} />
              </h2>
            </div>
            <div>
              <h2>
                Photoshop <span className="i ps">PS</span>
              </h2>
            </div>
            <div>
              <h2 className="redux-container">
                Redux
                <img
                  src={ReduxImg}
                  className="redux-image i"
                  alt="redux-logo"
                />
              </h2>
            </div>

            <div>
              <h2>
                Project Management
                <FontAwesomeIcon className="i" icon={faListCheck} />
              </h2>
            </div>
          </div>
        </section>
        <div className="connect">
          <h3 className="connect-text">
            Let's connect! Whether you're looking to discuss web development,
            share insights on the latest tech trends or explore potential
            collaborations, I'd love to hear from you!
          </h3>
          <Link to="/contact" className="btn">
            Say Hello
          </Link>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Home;
