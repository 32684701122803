import React from "react";
import { Outlet, Link } from "react-router-dom";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAddressCard,
  faHouse,
  faPen,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import { faGlobePointer } from "@fortawesome/pro-solid-svg-icons";
const Header = () => {
  const [toggle, setToggle] = useState(false);

  function handleToggle() {
    setToggle(!toggle);
    document.body.classList.toggle("nav-open");
  }
  return (
    <>
      <header>
        <div className="top"></div>
        <button
          onClick={handleToggle}
          className={`nav-toggle ${toggle ? "nav-open" : ""}`}
          aria-label="toggle navigation"
        >
          <span className="hamburger"></span>
        </button>

        <nav>
          <ul className="nav__list">
            <li className="nav__item">
              <Link to="/" className="nav__link" onClick={handleToggle}>
                <FontAwesomeIcon className="nav__icon" icon={faHouse} />
                Home
              </Link>
            </li>
            <li className="nav__item">
              <Link to="/about" onClick={handleToggle} className="nav__link">
                <FontAwesomeIcon className="nav__icon" icon={faAddressCard} />
                About Me
              </Link>
            </li>
            <li className="nav__item">
              <Link to="/work" onClick={handleToggle} className="nav__link">
                <FontAwesomeIcon className="nav__icon" icon={faGlobePointer} />
                My Work
              </Link>
            </li>
            <li className="nav__item">
              <Link to="/blog" onClick={handleToggle} className="nav__link">
                <FontAwesomeIcon className="nav__icon" icon={faPen} />
                Blog
              </Link>
            </li>
            <li className="nav__item">
              <Link to="/contact" onClick={handleToggle} className="nav__link">
                <FontAwesomeIcon className="nav__icon" icon={faPhone} />
                Contact
              </Link>
            </li>
          </ul>
        </nav>
      </header>

      <Outlet />
    </>
  );
};

export default Header;
