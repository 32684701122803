import React from "react";
import { Link } from "react-router-dom";
import Header from "../components/Header";
import Footer from "../components/Footer";
import ContactForm from "../components/ContactForm";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { faLinkedin } from "@fortawesome/free-brands-svg-icons";

const Contact = () => {
  return (
    <>
      <Header />
      <div className="main-container">
        <h2 className="contact-title">Get In Touch</h2>
        <div className="contact-container">
          <ContactForm />
          <ul className="contact-form">
            <li>
              <Link
                className="contact-link"
                to="mailto:getintouch@andrew-weaver.net"
              >
                <FontAwesomeIcon className="i" icon={faEnvelope} />
                <br />
                Send me an email
              </Link>
            </li>
            <li>
              <Link
                className="contact-link"
                to="https://www.linkedin.com/in/andrew-c-weaver/"
                target="_blank"
              >
                <FontAwesomeIcon className="i" icon={faLinkedin} />
                <br />
                Message me on LinkedIn
              </Link>
            </li>
          </ul>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Contact;
