import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import BlogImage from "../images/blog-image.jpg";

const Blog = () => {
  return (
    <>
      <Header />
      <div
        className="main-container blog"
        style={{
          backgroundImage: `url(${BlogImage})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <h2>Blog</h2>
        <p>Coming soon</p>
      </div>
      <div className="blog-footer">
        <Footer />
      </div>
    </>
  );
};

export default Blog;
