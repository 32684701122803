import React from "react";

const ContactForm = () => {
  return (
    <form
      action="https://formsubmit.co/getintouch@andrew-weaver.net"
      method="post"
      className="formContainer"
    >
      <input type="hidden" name="form-name" value="contact" />
      <input type="hidden" name="_template" value="basic" />
      <h2>Send me a message.</h2>
      <br />
      <div className="formElement">
        <label htmlFor="name">Name</label>
        <input
          type="text"
          id="name"
          name="name"
          placeholder="Your name.."
          required
        />
      </div>

      <div className="formElement">
        <label>E-mail</label>
        <input
          type="email"
          id="email"
          name="email"
          placeholder="Your email.."
          required
        />
      </div>

      <div className="formElement">
        <label htmlFor="message">Message</label>
        <textarea
          name="message"
          rows="8"
          cols="30"
          placeholder="Your message.."
          required
        />
      </div>
      <input type="text" name="_honey" style={{ display: "none" }} />
      <button type="submit" className="formButton btn">
        Submit
      </button>
    </form>
  );
};

export default ContactForm;
