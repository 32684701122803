import React from "react";
import { Link } from "react-router-dom";
import Header from "../components/Header";
import Footer from "../components/Footer";

const About = () => {
  return (
    <>
      <Header />
      <div className="main-container">
        <h2 className="about-title">About</h2>
        <p>
          I’m Andrew, I’m a 38-year-old father and husband with more than three
          years of self-taught experience in web development, I am a curious
          developer who challenges conventional wisdom, envisions endless
          possibilities, and is committed to continuous learning. My educational
          background is extensive, holding certifications in JavaScript,
          TypeScript, React.js, Express.js, Node.js, MongoDB, Redux, and UI
          design. I have dedicated time to honing my skills through platforms
          like LinkedIn Learning, Udemy, and Scrimba to stay abreast of the
          latest trends in front-end development.
        </p>
        <br />
        <p>
          From a young age, I have had a fascination with understanding how
          things operate, dismantling them, fixing any issues, and if possible,
          enhancing their functionality (except for my mums HI-FI system when I
          was 6, which taught me the importance of paying attention to detail).
        </p>
        <p>
          Although I initially had a keen interest in web development and even
          possessed a three inch thick book on HTML, life's twists and turns led
          me into the construction industry for a span of 16 years. During this
          period, I honed a diverse range of trade skills and made it my utmost
          priority to establish a deep understanding of my client's needs. By
          attentively listening to their requirements, I not only delivered what
          they desired but also utilized my expertise and meticulousness to
          offer valuable advice that would help them achieve their goals. This
          approach allowed me to provide a personalized and top-notch service.
          While I relished the variety of work in the construction industry, I
          always felt a sense of incompleteness. Consequently, I repeatedly
          found myself gravitating towards development in various forms. I even
          delved into the realm of Unity game engine and C#.
        </p>
        <br />
        <p>
          In 2020, I made the bold decision to fully commit to pursuing my true
          passion, which lies in web development. I am drawn to the diversity
          and innovation within this field, as it enables me to utilize my
          problem-solving abilities and design expertise to craft responsive,
          user-friendly, and inclusive websites and applications that have the
          potential to reach and educate individuals globally. I am enthusiastic
          about acquiring new skills and staying updated on the latest
          technologies, and I am constantly seeking opportunities to work
          alongside fellow developers and experts in order to produce impactful
          and top-notch products. Looking back, I only wish I had returned to
          this fulfilling path sooner.
        </p>
        <br />
        <p>
          Thank you for taking the time to read through (If you made it this
          far), if you would like to know more please, feel free to
          <Link to="/contact" className="about-contact-link">
            {" "}
            Contact Me.
          </Link>
        </p>
      </div>
      <Footer />
    </>
  );
};

export default About;
